import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`Deprecation Warning:`}</strong>{` This API is deprecated and it is scheduled to be removed on 03/01/2022. Please use (or migrate to) the new version of the `}<a parentName="p" {...{
          "href": "/viewer-authentication-api-v2-getting-started"
        }}>{`Viewer Authentication`}</a>{`.`}</p>
    </InlineNotification>
    <p>{`Once a hash is successfully validated by IBM Video Streaming, the player will store the hash in the end user’s browser. This lets the viewer resume watching the video when returning to the page without having authenticate again.`}</p>
    <p>{`You can specify an expiration date for the hash. When the viewer returns to the page after the expiration date, they must authenticate again.`}</p>
    <p>{`Expiration date must be`}</p>
    <ul>
      <li parentName="ul">{`Specified as UNIX timestamp in seconds`}</li>
      <li parentName="ul">{`Part of the hash by concatenating it to the rest of the parameters`}</li>
      <li parentName="ul">{`Included in the response object as “hashExpire”.`}</li>
      <li parentName="ul">{`JSON encoded as part of the response`}</li>
    </ul>
    <p>{`Example script in PHP for specifying hash expiration date`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// We specify an expiration date as a UNIX timestamp
// which is valid for one hour.
$expiration = time()+3600;

// We concatenate it to the rest of the parameters when creating the hash.
$hash = md5(implode('|', $userData).'|'.$expiration.'|'.$SHARED_SECRET_KEY);

// We include it in the response.
$response = array_chunk(
    array_merge($userData, ["hashExpire" => $expiration, "hash" => $hash]),
    1,
    true
);

// We encode the output into JSON format.
$json = json_encode($response);

`}</code></pre>
    <p>{`When validating the authentication response the IBM Video Streaming infrastructure checks`}</p>
    <ul>
      <li parentName="ul">{`If the expiration date is past`}</li>
      <li parentName="ul">{`If the expiration was hashed properly`}</li>
    </ul>
    <p>{`This way we can make sure that the expiration date comes from you and it has not been tampered with on the client side.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      